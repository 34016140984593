<template>
  <div class="Social">
    <!-- Banner !-->
    <div
      class="flex items-center w-full h-38em md:h-45em bg-layout_secondary bg-cover  bg-no-repeat bg-center pt-4 md:pt-8 pb-4 md:pb-8"
    >
      <div class="mx-5 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-12 lg:col-span-9">
            <span class="text-white font-Montserrat text-xl">RESPONSABILIDADE SOCIAL</span>
            <h1
              class="text-white font-Montserrat text-4xl md:text-6xl mt-3"
            >
              O que fazemos pela comunidade e meio-ambiente
            </h1>
          </div>
        </div>
      </div>
    </div>
    <!-- Comunidade !-->
    <div id="comunidade" class="py-12 md:py-28 mx-5 md:mx-12 2xl:mx-96">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-5">
          <img src="@/assets/boscardin_empresa.png" alt="boscardin empresa" />
        </div>
        <div class="col-span-12 md:col-span-7 md:px-12 md:pt-20">
            <h2
              class="text-gray-900 font-Montserrat font-semibold text-2xl md:text-4xl"
            >
              Comunidade
            </h2>
            <p class="text-gray-600 font-Montserrat text-base mt-5">
              Com o objetivo de melhorar a qualidade de vida das pessoas, desde o início da instalação em Curitiba, nossos valores estão direcionados em apoiar por meio de doação de pedras e afins para a construção de creches, asilos, igrejas, entre outros. Já foram beneficiadas mais de 2.000 entidades.
            </p>
            <p class="text-gray-600 font-Montserrat text-base mt-5">
              Além disso, visando a qualidade de vida dos nossos colaboradores apoiamos o acesso e a inserção de nossos colaboradores na prática esportiva, contribuindo para uma melhor qualidade de vida e integração dos mesmos à comunidade.
            </p>
        </div>
      </div>
    </div>
    <!-- Meio ambiente !-->
    <div id="ambiente" class="py-12 md:py-28 mx-5 md:mx-12 2xl:mx-96">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-7 md:px-12 md:pt-20">
            <h2
              class="text-gray-900 font-Montserrat font-semibold text-2xl md:text-4xl"
            >
              Meio-ambiente e segurança
            </h2>
            <p class="text-gray-600 font-Montserrat text-base mt-5">
              É nossa política priorizar e cumprir as regras e a legislação aplicável de meio ambiente e de segurança.
            </p>
            <ul class="list-disc ml-5">
              <li class="text-gray-600 font-Montserrat text-base mt-3">
                Estimulando internamente princípios de boas práticas de preservação e instituição de valor;
              </li>
              <li class="text-gray-600 font-Montserrat text-base mt-3">
                Compromisso de reduzir os impactos das atividades realizadas através de proteção da biodiversidade;
              </li>
              <li class="text-gray-600 font-Montserrat text-base mt-3">
                Proteção do meio ambiente participando do reflorestamento de árvores nativas da região onde se localizam as unidades;
              </li>
              <li class="text-gray-600 font-Montserrat text-base mt-3">
                Preservação total de áreas verdes não destinadas à exploração econômica;
              </li>
              <li class="text-gray-600 font-Montserrat text-base mt-3">
                Adoção de medidas que visem a não poluição dos recursos hídricos.
              </li>
            </ul>
        </div>
        <div class="col-span-12 md:col-span-5">
          <img src="@/assets/boscardin_meio_ambiente.png" alt="boscardin empresa" />
        </div>
      </div>
    </div>
    <!-- Interessado !-->
    <div id="interessado" class="py-12 md:py-28 bg-layout_branco bg-cover bg-no-repeat bg-center">
      <div class="mx-5 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 md:col-span-8">
            <h2
                class="text-gray-900 font-Montserrat text-2xl md:text-5xl"
              >
                Interessado nos nossos serviços? Entre em contato com a gente.
            </h2>
            <button
              @click="$modal.show('modal_zap')"
              class="border-none bg-secondary text-base font-semibold text-white py-6 font-Montserrat mt-4 w-full md:w-60 hover:bg-primary mt-10"
            >
              Entrar em contato
            </button>
          </div>
        </div>
      </div>
    </div>
 </div>
</template>

<script>
export default {}
</script>
